<template>
	<div class="video">
		<div class="material_screen">
			<div class="mian">
				<div class="inner_nav clearfix">
					<div class="fl inner_nav_l">当前位置：字体设计方法指南</div>
					<div class="fr inner_nav_r">
						共 <span>{{ total }}</span> 个视频
					</div>
				</div>
				<router-link class="inner_banner" :to="{
            name: banner.url,
            query: { id: banner.links_id != 0 ? banner.links_id : '' },
          }">
					<img :src="banner.thumb" alt="" />
				</router-link>
			</div>
		</div>
		<div class="mian">
			<div class="list">
				<div class="video_list video_lists clearfix" v-if="list.length > 0">
					<!-- wow animate__animated animate__fadeInUp -->
					<div class="daily_ul" v-for="(row, i) in list" :key="i">
						<div class="video_li" :style="{ animationDelay: 0.1 * index + 's' }"
							v-for="(item, index) in row" :key="index" @click="toGuideDetail(item.id)">
							<div class="video_li_box">
								<img v-lazy="item.thumb" alt="" />
								<div class="video_li_mask"></div>
								<div class="video_li_play">
									<img src="../../assets/images/icon_play@2x.png" alt="" />
								</div>
							</div>
							<!-- <div class="video_li_name">{{item.title}}</div>
                            <div class="video_li_reading clearfix">
                                <div class="fl">{{item.level_name}}丨{{item.font_style_name}}</div>
                                <div class="fr clearfix">
                                    <div class="fl clearfix video_li_reading_k">
                                        <img class="fl" src="../../assets/images/yulan.svg" alt="">
                                        <span class="fl">{{item.browse_num||0}}</span>
                                    </div>
                                    <div class="fl clearfix">
                                        <img class="fl" src="../../assets/images/shoucang.svg" alt="">
                                        <span class="fl">{{item.collect_num||0}}</span>
                                    </div>
                                </div>
                            </div> -->

							<div class="video_li_reading clearfix">
								<div class="fl">
									<div class="video_li_name">{{ item.title }}</div>
								</div>
								<div class="fr clearfix">
									<div class="fl clearfix video_li_reading_k">
										<img class="fl" src="../../assets/images/yulan.svg" alt="" />
										<span class="fl">{{ item.browse_num || 0 }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="loading" v-if="list.length < 1">
					正在加载中
					<img src="../../assets/images/jiazai.png" alt="" />
				</div>
				<el-empty v-if="total == 0 && emptyShow" description="暂无数据"></el-empty>
				<page v-if="total > limit && list.length>=1" :page="page" :limit="limit" :total="total" ref="page"></page>
			</div>
			<!-- 视频教程 -->
			<div>
				<div class="public_title">
					<div class="public_title_l public_title_ls">
						<div class="public_title_name">推荐视频</div>
					</div>
					<!-- <div class="public_title_more">查看更多</div> -->
				</div>
				<div class="video_list clearfix" v-if="videoList.length > 0">
					<!-- wow animate__animated animate__fadeInUp -->
					<div class="fl video_li video_lis" :style="{ animationDelay: 0.1 * index + 's' }"
						v-for="(item, index) in videoList" :key="index" @click="toDetail(item.id)">
						<div class="video_li_box">
							<div class="video_thumb"><img v-lazy="item.thumb" alt="" /></div>
							<div class="video_li_mask"></div>
							<div class="video_li_play">
								<img src="../../assets/images/icon_play@2x.png" alt="" />
							</div>
						</div>
						<!-- <div class="video_li_name">{{item.title}}</div>
                        <div class="video_li_reading clearfix">
                            <div class="fl">{{item.level_name}}丨{{item.font_style_name}}</div>
                            <div class="fr clearfix">
                                <div class="fl clearfix video_li_reading_k">
                                    <img class="fl" src="../../assets/images/yulan.svg" alt="">
                                    <span class="fl">{{item.browse_num||0}}</span>
                                </div>
                                <div class="fl clearfix">
                                    <img class="fl" src="../../assets/images/shoucang.svg" alt="">
                                    <span class="fl">{{item.collect_num||0}}</span>
                                </div>
                            </div>
                        </div> -->
						<div class="video_li_reading clearfix">
							<div class="fl">
								<div class="video_li_name">{{ item.title }}</div>
							</div>
							<div class="fr clearfix">
								<div class="fl clearfix video_li_reading_k">
									<img class="fl" src="../../assets/images/yulan.svg" alt="" />
									<span class="fl">{{ item.browse_num || 0 }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<el-empty v-if="videoList.length < 1 && emptyShow" description="暂无数据"></el-empty>
			</div>
			<!-- 推荐专题 -->
			<div>
				<div class="public_title">
					<div class="public_title_name">素材专题推荐</div>
					<router-link to="/project" class="public_title_more">查看更多</router-link>
				</div>
				<div class="project clearfix" v-if="projectList.length > 0">
					<!-- wow animate__animated animate__fadeInUp -->
					<div class="fl project_li" :style="{ animationDelay: 0.1 * index + 's' }"
						v-for="(item, index) in projectList" :key="index" @click="toProDetailFun(item.id)">
						<div class="project_li_box">
							<img v-lazy="item.thumb" alt="" />
							<div class="project_li_hot" v-if="item.is_hot == 1">HOT</div>
							<div class="project_li_buttom">{{ item.title }}</div>
							<div class="project_li_mask">
								<p>{{ item.title }}</p>
							</div>
							<div class="project_li_collect" v-if="item.is_collect == 0"
								@click.stop="collectFun(1, item.id, index)">
								<img src="../../assets/images/weishoucang.svg" alt="" />
							</div>
							<div class="project_li_collect" v-else @click.stop="uncollectFun(1, item.id, index)">
								<img src="../../assets/images/icon_collection@2x.svg" alt="" />
							</div>
						</div>
					</div>
				</div>
				<el-empty v-if="projectList.length < 1 && emptyShow" description="暂无数据"></el-empty>
			</div>
		</div>
		<!-- 侧边导航 -->
		<sidebar ref="downtotal"></sidebar>
		<!-- 收藏 -->
		<div class="mask" v-if="isShow" @click="closeMsg">
			<collection isMask="1" :collectionId="collId" :type="type" @closeFun="closeFun"></collection>
		</div>
	</div>
</template>

<script>
	import sidebar from "../../components/sidebar.vue";
	import collection from "../../components/collection.vue";
	import page from "../../components/page.vue";
	export default {
		name: "videoGuide",
		components: {
			sidebar,
			collection,
			page,
		},
		data() {
			return {
				banner: {}, //banner

				video_total: 0, //作品总数
				unfold: true,
				themeList: [], //分类

				indexKey: 1, //刷新

				list: [],
				projectList: [],
				videoList: [],
				emptyShow: false,

				isShow: false, //收藏夹显示

				// 分页
				total: 0, //总数
				page: 1, //页数
				limit: 80, //每页个数

				// 收藏参数
				type: "", //类型
				collId: "", //收藏id

				// 接收参数
				navId: "",
				navIndex: 0,
				navName: "",
				font_style: 0,
				font_cate: 0,
				scroll: 0,
				series: 0
			};
		},
		mounted() {
			this.$parent.routerIndex = 8;
			this.$parent.showIndex = 1;
			this.bannerOneban();
			this.listFun();
		},
		methods: {
			// 获取内部banner、
			bannerOneban() {
				var that = this;
				this.$api.POST(
					this.$face.bannerOneban, {
						location: 3,
					},
					function(res) {
						switch (res.data.links) {
							case 0:
								res.data.url = "";
								break;
							case 1:
								res.data.url = "material";
								break;
							case 2:
								res.data.url = "video";
								break;
							case 3:
								res.data.url = "deformation";
								break;
							case 4:
								res.data.url = "ranking";
								break;
							case 5:
								res.data.url = "dailyWord";
								break;
							case 6:
								res.data.url = "fontSearch";
								break;
							case 7:
								res.data.url = "dailyWordUpload";
								break;
							case 8:
								res.data.url = "vip";
								break;
							case 9:
								res.data.url = "project";
								break;
							case 10:
								res.data.url = "rankingDetail";
								break;
							case 11:
								res.data.url = "projectDetail";
								break;
						}
						that.banner = res.data;
					}
				);
			},

			// 计算分类是否需要展示更多
			countFun() {
				this.navList.forEach((row, i) => {
					var wid = 0,
						boxw = 0;
					if (this.$refs["getTitleRefs" + i]) {
						this.$refs["getTitleRefs" + i].forEach((item) => {
							wid += item.clientWidth;
						});
					}
					if (this.$refs["class_list" + i]) {
						this.$refs["class_list" + i].forEach((item) => {
							boxw = item.clientWidth - 152;
						});
					}
					if (wid > boxw) {
						row.unfold = true;
					} else {
						row.unfold = false;
					}
				});
				console.log(this.navList);
			},

			// 获取列表
			listFun() {
				var that = this;
				var params = {
					page: this.page,
					limit: this.limit
				};
				if (this.$refs.page && this.page == 1) {
					this.page == 1
					this.$refs.page.currentPage = 1;
				}
				this.$api.POST(this.$face.video_font_guide_index, params, function(res) {
					that.list = that.$utile.listDataNo(res.data.video.lists);
					that.total = res.data.video.total;
					that.projectList = res.data.special;
					that.videoList = res.data.video_tj;
					that.emptyShow = true;
				});
			},
			toGuideDetail(id){
				localStorage.setItem("clearAll", 2);
				//this.$router.push({ name: "videoDetail", query: { id: id } });
				let routeData = this.$router.resolve({
					name: "videoGuideDetail",
					query: {
						id: id,
					},
				});
				window.open(routeData.href, "_blank");
			},
			// 查看详情
			toDetail(id) {
				localStorage.setItem("clearAll", 2);
				//this.$router.push({ name: "videoDetail", query: { id: id } });
				let routeData = this.$router.resolve({
					name: "videoDetail",
					query: {
						id: id,
					},
				});
				window.open(routeData.href, "_blank");
			},
			//推荐专题
			toProDetailFun(id) {
				localStorage.setItem("clearAll", 2);
				this.$router.push({
					name: "projectDetail",
					query: {
						id: id
					}
				});
				// let routeData = this.$router.resolve({
				// 	name: "projectDetail",
				// 	query: {
				// 		id: id
				// 	},
				// });
				// window.open(routeData.href, "_blank");
			},

			//收藏列表
			collectFun(type, id, index) {
				if (localStorage.getItem("access_token")) {

					this.type = type;
					this.collId = id;
					this.isShow = true;
				} else {
					this.$parent.loginShow();
				}
			},
			//取消收藏
			uncollectFun(type, id, index) {
				var that = this;
				var params = {
					coll_id: id,
					type: type,
				};
				this.$api.POST(this.$face.collectionColl, params, function(res) {
					that.projectList[index].is_collect = 0;
					that.$utile.prompt("success", "取消收藏");
					that.listFun();
				});
			},
			jumpBannerFun(url, param) {
				if (param.links == 0) {
					return;
				}
				if (param.links == 15) {
					window.open(param.urls);
					return;
				}
				if (param.festival != 0) {
					localStorage.setItem("festival", param.festival);
				}
				if (param.solar_terms != 0) {
					localStorage.setItem("solar_terms", param.solar_terms);
				}
				if (param.industry != 0) {
					localStorage.setItem("industry", param.industry);
				}

				if (param.font_cate != 0) {
					localStorage.setItem("font_cate", param.font_cate);
				}
				if (param.font_style != 0) {
					localStorage.setItem("font_style", param.font_style);
				}
				if (param.series != 0) {
					localStorage.setItem("series", param.series);
				}
				let routeData = this.$router.resolve({
					name: url
				});
				window.open(routeData.href, "_blank");
				this.bannerrecord(param.id)
			},

			// 点击banner统计次数接口
			bannerrecord(id) {
				console.log('bannerid', id)
				var that = this;
				var params = {
					banner_id: id,
				};
				this.$api.POST(this.$face.bannerecord, params, function(res) {
					console.log('bannerres', res)
				});
			},

			//关闭收藏
			closeFun(e) {
				this.isShow = false;
				if (e != 1) {
					this.listFun();
				}
			},
			// 点击遮罩层关闭
			closeMsg(ev) {
				let dom = document.getElementById("child");
				if (dom) {
					if (!dom.contains(ev.target)) {
						this.isShow = false;
					}
				}
			},
		},
		watch: {
			"$store.state.navList"(newval) {
				this.navList = JSON.parse(JSON.stringify(newval.video_list));

				// this.video_total = newval.video_total;
				this.navList.forEach((element) => {
					element.index = 0;
					if (element.list && element.list.length > 0) {
						element.list.forEach((item, index) => {
							if (
								(this.navId && this.navId == item.id) ||
								(this.navName && this.navName == item.name) ||
								(this.font_cate != 0 && this.font_cate == item.id) ||
								(this.font_style != 0 && this.font_style == item.id) ||
								(this.series != 0 && this.series == item.id)
							) {
								element.index = index;
								element.isShow = false;
								console.log('index', element.index);
							}
						});
					}
				});

				localStorage.removeItem("video_name");
				localStorage.removeItem("font_cate");
				localStorage.removeItem("font_style");
				localStorage.removeItem("series");
				// this.navList.forEach((element) => {
				//   if (this.navId && this.navId == element.id) {
				//     element.index = this.navIndex;
				//     element.isShow = false;
				//     console.log(element.index);
				//   } else {
				//     element.index = 0;
				//     element.isShow = false;
				//   }
				// });
				this.$nextTick(() => {
					this.countFun();
				});
				this.listFun();
			},
		},
		// 路由跳转之前保存当前滚动条的位置。
		beforeRouteLeave(to, from, next) {
			console.log(from);
			this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
			next();
			// if (to.name == 'material' || to.name == 'video') { // 需要缓存的路由name
			// 	to.meta.keepAlive = false
			//     // from.meta.keepAlive = true
			// 	next()
			// }else{
			// 	from.meta.keepAlive = true
			// 	next()
			// }
		},
		activated() {
			console.log(this.scroll);
			this.$nextTick(() => {
				this.$parent.routerIndex = 8;
				this.$parent.showIndex = 1;
				//判断是否点击的导航，导航清空筛选项
				if (localStorage.getItem("clearAll") == 1) {
					this.navId = "";
					this.navIndex = 0;
					this.navList.forEach((element) => {
						element.index = 0;
						element.isShow = false;
					});

					this.page = 1;
					if (this.$refs.page) {
						this.$refs.page.currentPage = 1;
					}
				} else if (localStorage.getItem("clearAll") == 2) {
					setTimeout(() => {
						document.documentElement.scrollTop = this.scroll;
					}, 0);
				} else if (localStorage.getItem("clearAll") == 3) {
					this.navList.forEach((element) => {
						element.index = 0;
						element.isShow = false;
						if (localStorage.getItem("antString")) {
							element.list.forEach((item, index) => {
								if (localStorage.getItem("antString") == item.name) {
									element.index = index;
								}
							});
						}
					});
				} else {
					if (this.$route.query.id) {
						this.navId = this.$route.query.id;
					}
					if (this.$route.query.index) {
						this.navIndex = this.$route.query.index;
					}

					this.navList.forEach((element) => {
						element.index = 0;
						if (element.list && element.list.length > 0) {
							element.list.forEach((item, index) => {
								if (this.navId && this.navId == item.id) {
									element.index = index;
									element.isShow = false;
									console.log(element.index);
								}
							});
						}
					});
				}
				this.$forceUpdate();
				this.$nextTick(() => {
					this.countFun();
				});
				// localStorage.removeItem("clearAll");
				// localStorage.removeItem("antString");
				this.listFun();
			});
		},
	};
</script>

<style scoped>
	.el-empty {
		position: relative;
		z-index: 97;
		background-color: #f6f6f6;
	}

	.loading {
		width: 100%;
		height: 800px;
		text-align: center;
		font-size: 20px;
		color: #999999;
		text-align: center;
		position: absolute;
		top: 50px;
		left: 0;
		z-index: 1;
		padding-top: 100px;
		box-sizing: border-box;
	}

	.loading img {
		width: 24px;
		height: 24px;
		display: inline-block !important;
		vertical-align: middle;
		animation: fadeum 5s infinite;
	}

	@keyframes fadeum {
		0 {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	.video {
		background-color: #f6f6f6;
	}

	.material_screen {
		width: 100%;
		background: #ffffff;
		/* min-height: 458px; */
		padding-bottom: 1px;
	}

	.inner_nav {
		padding: 27px 0 17px;
	}

	.inner_nav_l {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 12px;
		letter-spacing: 0px;
		color: #999999;
	}

	.inner_nav_r {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 12px;
		letter-spacing: 0px;
		color: #999999;
	}

	.inner_nav_r span {
		color: #5957ff;
		font-weight: bold;
		font-size: 16px;
	}

	.inner_banner {
		display: block;
		margin-bottom: 32px;
	}

	.inner_banner img {
		width: 100%;
	}

	.material_class_list {
		border-bottom: 1px solid #eeeeee;
		transition: max-height 0.6s;
		max-height: 400px;
		overflow: hidden;
	}

	.material_class_list.min {
		max-height: 0;
		overflow: hidden;
	}

	.material_class_item {
		position: relative;
		margin-bottom: 17px;
	}

	.class_list_title {
		position: absolute;
		top: 0;
		left: 0;
		height: 30px;
		line-height: 30px;
		color: #999999;
	}

	.class_list {
		width: 100%;
		padding-left: 52px;
		padding-right: 100px;
		box-sizing: border-box;
	}

	.class_list.mini {
		height: 40px;
		overflow: hidden;
	}

	.class_list>div {
		height: 30px;
		line-height: 28px;
		font-size: 14px;
		text-align: center;
		color: #333;
		/* border: solid 1px transparent; */
		box-sizing: border-box;
		margin: 0 2px;
		margin-bottom: 10px;
		padding: 0 10px;
		border-radius: 10px;
		cursor: pointer;
	}

	.class_list>div:hover {
		color: #221eff;
	}

	.class_list>div.active {
		color: #221eff;
		background: rgba(235, 235, 255, 0.6);
	}

	.class_list_unfold {
		position: absolute;
		height: 30px;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #5957ff;
		cursor: pointer;
	}

	.class_list_unfold img {
		margin-left: 4px;
	}

	.sort_l {
		font-size: 14px;
		color: #333333;
		line-height: 54px;
	}

	.sort_l>div:nth-child(1) {
		color: #999999;
	}

	.sort_l_box {
		position: relative;
		height: 54px;
		display: flex;
		align-items: center;
	}

	.sort_l_s {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 0 12px;
		margin-right: 17px;
		height: 30px;
		border-radius: 10px;
	}

	.sort_l_s.active {
		color: #221eff;
		background: rgba(235, 235, 255, 0.6);
	}

	.sort_l_s:hover {
		color: #221eff;
	}

	.sort_l_ul {
		width: 100%;
		position: absolute;
		top: 54px;
		left: 0;
	}

	.sort_l_ul>div {
		text-align: center;
		background: #fff;
		cursor: default;
		transition: 0.3s;
	}

	.sort_l_ul>div:hover {
		background: #f6f7f9;
	}

	.sort_r {
		font-size: 14px;
		color: #333333;
		line-height: 54px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.sort_r:hover {
		color: #5957ff;
	}

	.sort_r img {
		margin: 0 6px 0 8px;
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.sort_r.min img {
		transform: rotate(0);
	}

	.list {
		padding-bottom: 99px;
		min-height: 300px;
		position: relative;
	}

	/* 视频教程 */
	.video_list {
		margin: 28px -13.33px 24px;
		position: relative;
		z-index: 9;
		background-color: #f6f6f6;
		min-height: 200px;
	}

	.video_lists {
		display: flex;
	}

	.daily_ul {
		margin: 0 13.33px;
		width: calc(100% / 4 - 26.66px);
	}

	.video_li {
		width: 330px;
		margin: 0 0 24px;
		background: #fff;
		border-radius: 10px;
		padding: 15px;
		box-sizing: border-box;
	}

	.video_lis {
		margin: 0 13.33px 24px;
	}

	.video_li_box {
		width: 100%;
		height: 198px;
		border-radius: 10px;
		position: relative;
		overflow: hidden;
	}

	.video_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.video_li_play {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 101;
		margin-left: -37.5px;
		margin-top: -37px;
		width: 74px;
		height: 75px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		opacity: 0;
	}

	.video_li:hover .video_li_mask {
		opacity: 1;
	}

	.video_li:hover .video_li_play {
		opacity: 1;
	}

	.video_li_name {
		/* height: 52px;
    line-height: 49px; */
		font-size: 17px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #333333;
		width: 230px;
		overflow: hidden;
		/* 第二步：让文本不会换行， 在同一行继续 */
		white-space: nowrap;
		/* 第三步：用省略号来代表未显示完的文本 */
		text-overflow: ellipsis;
	}

	.video_li_reading {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #999999;
		margin-top: 10px;
	}

	.video_li_reading img {
		width: auto;
		height: 20px;
		margin-right: 4px;
	}

	.video_li_reading span {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #999999;
	}

	.video_li_reading_k {
		margin-right: 16px;
	}

	/* 专题 */
	.project {
		margin: 28px -13.33px 40px;
	}

	.project_li {
		width: 330px;
		height: 300px;
		margin: 0 13.33px 30px;
		padding-top: 15px;
		background: url(../../assets/images/bg_tjzt2x.png) no-repeat;
		background-size: 311px auto;
		background-position: center top;
	}

	.project_li_box {
		position: relative;
		overflow: hidden;
		border-radius: 10px;
		width: 330px;
		height: 300px;
	}

	.project_li_box>img,
	.video_li_box>img,
	.daily_li_box>img {
		/* position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); */

		width: 100%;
		height: 100%;
		object-fit: contain;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_hot {
		position: absolute;
		top: 14px;
		left: -10px;
		width: 62px;
		height: 40px;
		background-color: #df0003;
		border-radius: 10px;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 40px;
		text-align: center;
		letter-spacing: 0px;
		color: #ffffff;
		z-index: 101;
		transition: 0.5s;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.project_li:hover .project_li_hot {
		left: 15px;
		padding-left: 0px;
	}

	.project_li_buttom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 45px;
		text-align: left;
		padding: 0 16px;
		box-sizing: border-box;
		line-height: 45px;
		color: #fff;
		font-size: 15px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-webkit-border-radius: 0;
		border-radius: 0;
		background: rgba(0, 0, 0, 0.4);
		opacity: 1;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.project_li_collect {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 14px;
		right: 15px;
		z-index: 100;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		cursor: pointer;
	}

	.project_li_collect img {
		width: 100%;
		height: 100%;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_collect:hover img {
		opacity: 0.8;
	}

	.project_li:hover .project_li_box>img,
	.video_li:hover .video_li_box>img,
	.daily_li:hover .daily_li_box>img {
		transform: scale(1.08);
	}

	.project_li:hover .project_li_buttom {
		opacity: 0;
	}

	.project_li:hover .project_li_mask,
	.project_li:hover .project_li_collect {
		opacity: 1;
	}

	/* @media screen and (max-width: 1600px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 280px;
            height: 184.4px;
        }
        .project_li{
            background-size: 261px auto;
        }
        .entrance_nav_li{
            width: 280px;
        }
        .provide_li{
            width: 270px;
        }
        .member_box{
            width: 580px;
        }
    }
    @media screen and (max-width: 1400px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 240px;
            height: 158px;
        }
        .project_li{
            background-size: 221px auto;
        }
        .entrance_nav_li{
            width: 240px;
        }
        .provide_li{
            width: 230px;
        }
        .member_box{
            width: 500px;
        }
    } */

	.sidebar_r {
		right: 2%;
		left: auto;
	}

	.video_lis .video_li_box {
		height: 198px;
	}

	.video_lis .video_thumb {
		position: relative;
		height: 198px;
		transition: all 0.3s ease-in-out;
	}

	.video_lis .video_thumb>img {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: auto;
	}

	.video_lis:hover .video_thumb {
		transform: scale(1.08);
	}

	.material_search {
		width: 100%;
		height: 70px;
		background-color: #f6f7f9;
		border-radius: 10px;
		border: solid 1px #eeeeee;
		box-sizing: border-box;
		padding-right: 160px;
		position: relative;
		margin-bottom: 12px;
	}

	.material_search input {
		background: transparent;
		width: 100%;
		height: 100%;
		padding: 0 24px;
		box-sizing: border-box;
		font-size: 16px;
	}

	.material_search input::placeholder {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999999;
	}

	.material_search_button {
		position: absolute;
		top: 2px;
		right: 2px;
		width: 150px;
		height: 64px;
		background-image: linear-gradient(0deg, #5957ff 0%, #acaaff 100%);
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>